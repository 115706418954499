import React from 'react';
import CustomersTemplate from '@components/CustomersTemplate/index';
import metaImage from '@page-components/case-studies/assets/Cellarity-case-study-page-thumb.jpg';

const CellarityPage = () => {
  const sections = [
    {
      bodyTitle: "Cellarity's cell-centric approach to drug discovery",
      bodyText: (
        <>
          <p>
            The traditional approach in drug discovery has been to reduce disease biology into a single molecular target, then leverage
            high-throughput screening to identify molecules that bind to such targets. In contrast, Cellarity focuses on the whole cell, as
            disease often isn&apos;t driven by one mechanism or protein alone.
          </p>
          <br />
          <p>
            “We are a drug discovery organization using AI to find drugs that were never possible before,” says Parul Bordia Doshi, Chief
            Data Officer, Cellarity. “We have a unique way of approaching things. We don&apos;t start with a target and simplify biology
            like most others do. We instead embrace the complexity and do drug discovery from the point of view of the whole cell.”
          </p>
          <br />
          <p>
            Using single-cell technologies, scientists at Cellarity identify cellular drivers of the transition from health to disease, then
            apply deep learning models to create drugs that reverse disease at the cellular level.
          </p>
        </>
      ),
    },
    {
      bodyTitle: 'Need for a better data management solution',
      bodyText: (
        <>
          <p>
            The Cellarity data science and visualization team needed to analyze transcriptomic data from hundreds of millions of single
            cells to support deep learning models that inform drug development. They realized their file-based storage approach was limiting
            in both scale and functionality, which was resulting in inefficient data wrangling across their teams of engineers and
            scientists.
          </p>
          <br />
          <p>Handling 100s of millions of cells spread across datasets with a file-based approach had many challenges:</p>
        </>
      ),
      bodyList: [
        <>
          <strong>Lack of a single source of truth:</strong> Single cell data was not cataloged to be easily findable and accessible, and
          was not in compliance with FAIR data principles.
        </>,
        <>
          <strong>Sluggish access to data:</strong> Single-cell data files had to be downloaded and loaded into main memory by each
          scientist, which was an inefficient process.
        </>,
        <>
          <strong>Slowed collaboration:</strong> Tedious downloads and uploads as well as siloed analytics in Excel sheets meant there were
          no standard processes for concurrent access and versioning, which prevented scientists from collaborating on the same dataset
        </>,
        <>
          <strong>Inability to drive traceability and reproducibility:</strong> Lack of ability to analyze data sets, conditions and changes
          over time made it difficult to make informed decisions and avoid mistakes.
        </>,
        <>
          <strong>Inability to handle new data modalities:</strong> The file-based approach made it hard for teams to efficiently query
          across multiple datasets, preventing them from incorporating modalities like spatial transcriptomics into their research.
        </>,
      ],
    },
    {
      bodyTitle: 'Why Cellarity chose TileDB',
      bodyText: (
        <>
          <p>
            The Cellarity computational biologists and machine learning team learned about TileDB via the{' '}
            <a href="https://tiledb.com/blog/tiledb-launches-soma-apis-for-single-cell-data" style={{ textDecoration: 'underline' }}>
              collaboration with the Chan Zuckerberg Initiative
            </a>{' '}
            on SOMA and the TileDB-SOMA libraries. SOMA is a flexible, open-source API spec designed to enable access to any dataset that
            can be modeled as groups of annotated sparse 2D matrices, which is ideal for single-cell data.
          </p>
          <br />
          <p>
            The team turned their AnnData single-cell datasets into a collection of SOMAs and started with a database solution that enabled
            highly performant queries across datasets and experiments as well as data and code collaboration. TileDB Cloud fulfilled all
            their requirements for analysis-ready single cell data on cloud storage:
          </p>
        </>
      ),
      bodyList: [
        <>
          <strong>Data flexibility:</strong> Ability to model and transition AnnData into TileDB SOMA objects
        </>,
        <>
          <strong>Versatile support across all requirements:</strong> Only TileDB put forth experts across data management, single-cell and
          devops infrastructure.
        </>,
        <>
          <strong>Performance:</strong> Scale to unprecedented single cell datasets, all living on object stores with distributed analysis.
        </>,
        <>
          <strong>Open and future-ready architecture:</strong> Easy integration with existing solutions and applications such as Saturn
          Cloud.
        </>,
      ],
    },
    {
      bodyTitle: 'Reducing the data engineering burden for a promising future',
      bodyText: (
        <>
          <p>
            “We believe that TileDB is a FAIR platform. We now have a catalog, a single source of truth, and we can always go back to it and
            update it at scale in parallel,” says James Gatter, software engineer at Cellarity. “In addition, TileDB Cloud&apos;s compute
            power, and the ability to slice through TileDB arrays is really great. We&apos;ve gained the capacity to run queries and compute
            across our catalog.”
          </p>
          <br />
          <p>
            With single cell data amassed from millions of cells, the Cellarity team could not easily interoperate across individual
            experiments using their old architecture. With TileDB, the ability to aggregate those datasets has drastically improved. In
            addition, it is easier to make atlases and retrain. “Before it would take us days to build an atlas. Now it&apos;s just a very
            programmatic solution for doing that in parallel and that takes about an hour,” says Gatter.
          </p>
          <br />
          <p>
            “In addition, before TileDB, reading across catalogs and trying to update them to conform to the newest standards of data would
            have taken the team a significant amount of time. With TileDB we are able to make those changes within an hour,” says Gatter.
          </p>
          <br />
          <p>
            “TileDB solved a very unique problem for us,” says Doshi. “TileDB has improved our computational performance and organizational
            efficiency. By reducing the data engineering burden, our ML and computational scientists can focus on the science.”
          </p>
        </>
      ),
      bodyList: [
        <>
          <strong>FAIR data catalog</strong> for single-cell data to drive efficiency
        </>,
        <>
          <strong>&lt; 1 hour</strong> to build a single-cell atlas which previously took days
        </>,
        <>
          <strong>&lt; 1 hour</strong> to update single-cell catalogs to conform to the newest standards of data
        </>,
      ],
    },
  ];

  return (
    <CustomersTemplate
      pageName="customers-cellarity"
      helmet={{
        title: 'Case Study: Cellarity | TileDB',
        description: 'How Cellarity Powers Next Generation Drug Discovery Using Single-Cell Data.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Customer Case Study"
      title="How Cellarity Powers Next Generation Drug Discovery Using Single-Cell Data"
      description={
        <>
          Founded in 2019 in Somerville, MA, Cellarity is a therapeutics company that emerged from Flagship Pioneering. Cellarity operates
          at the intersection of biology, chemistry, high-dimensional data and machine learning. Their experts leverage cutting-edge
          biological and computational technologies to explore cellular and molecular components for drug discovery.
        </>
      }
      gradient="cellarity"
      sections={sections}
      cardText="TileDB solved a very unique problem for us. TileDB has improved our computational performance and organizational efficiency. By reducing the data engineering burden, our ML and computational scientists can focus on the science."
      cardAuthor="Parul Bordia Doshi"
      cardAuthorDescription="Chief Data Officer at Cellarity"
      domain="Biotech"
      datatypes={['Single-cell', 'RNA-seq']}
    />
  );
};

export default CellarityPage;
